<template>
  <v-row>
    <!-- <v-card-title class="align-start">
      <span class="font-weight-semibold">Statistics Card</span>
      <v-spacer></v-spacer>
      <v-btn icon small class="me-n3 mt-n2">
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title> -->

    <!-- <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text--primary me-1">Total 48.5% Growth</span>
      <span>😎 this month</span>
    </v-card-subtitle> -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-row class="justify-space-between">
            <v-col cols="4" md="2" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation('Visa').color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation('Visa').icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">TOKO</p>
                <h3 class="text-xl font-weight-semibold">{{ jmlvisa }}</h3>
              </div>
            </v-col>
            <v-col cols="4" md="2" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation('Members').color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation('Members').icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">MEMBER</p>
                <h3 class="text-xl font-weight-semibold">{{ jmlmember }}</h3>
              </div>
            </v-col>
            <v-col cols="4" md="2" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation('Product').color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation('Product').icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">PRODUK</p>
                <h3 class="text-xl font-weight-semibold">{{ jmlstatusijintinggal }}</h3>
              </div>
            </v-col>
            <v-col cols="4" md="2" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation('Revenue').color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation('Revenue').icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">PENDAPATAN</p>
                <h3 class="text-sm font-weight-semibold">{{ jmltransaksiidr | currency }}</h3>
              </div>
            </v-col>
            <!-- <v-col cols="4" md="2" class="d-flex align-center">
              <v-avatar size="44" :color="resolveStatisticsIconVariation('Revenue').color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ resolveStatisticsIconVariation('Revenue').icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">REVENUE (USD)</p>
                <h3 class="text-sm font-weight-semibold">{{ jmltransaksiusd | dollar_currency }}</h3>
              </div>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-card-title class="text-no-wrap pt-1 ps-2"> TRANSACTION PAID🥳 FROM PAYMENT GATEWAY </v-card-title>
          <v-text-field
            class="pt-5"
            label="Search"
            @keyup="doSearchPaid()"
            v-model="search"
            dense
            outlined
          ></v-text-field>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">NO</th>
                  <th class="text-center text-uppercase">FIRST NAME</th>
                  <th class="text-center text-uppercase">TRANSACTION DATE</th>
                  <th class="text-center text-uppercase">PERMIT STAY</th>
                  <th class="text-center text-uppercase">REMARK</th>
                  <th class="text-center text-uppercase">LOS</th>
                  <th class="text-center text-uppercase">TOTAL (IDR)</th>
                  <th class="text-center text-uppercase">PROCESS</th>
                  <th class="text-center text-uppercase">FINISH</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in displayedPaid()" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-center">{{ data.nama_depan }} {{ data.nama_belakang }}</td>
                  <td class="text-center">
                    {{ data.tgl_transaksi_pemesanan | date }}
                  </td>
                  <td class="text-center">
                    <span v-html="data.permitstays"></span>
                  </td>
                  <td class="text-center">
                    {{ data.remark }}
                  </td>
                  <td class="text-center">
                    {{ data.los }}
                  </td>
                  <td class="text-center">
                    {{ data.total_bayar | currency }}
                  </td>
                  <td class="text-center">
                    <span v-if="data.dibuatkan == 'B'">
                      <v-btn
                        @click="processPost(data.id_transaksi_pemesanan, index)"
                        color="success"
                        class="mt-2"
                        type="button"
                        small
                      >
                        <v-icon dark color="white">
                          {{ icons.mdiClipboardArrowUp }}
                        </v-icon>
                      </v-btn>
                    </span>
                    <span v-else>
                      <span v-if="data.dibuatkan == 'S'">
                        <v-btn outlined color="white" class="mt-2" type="button" small>
                          <v-icon dark color="success">{{ icons.mdiCheckboxMarkedCircle }} </v-icon></v-btn
                        ></span
                      >
                    </span>
                  </td>
                  <td class="text-center">
                    <span v-if="data.dibuatkan == 'S'">
                      <span v-if="data.diserahkan == 'B'">
                        <v-btn
                          @click="finishPost(data.id_transaksi_pemesanan, index)"
                          color="error"
                          class="mt-2"
                          type="button"
                          small
                        >
                          <v-icon dark color="white">
                            {{ icons.mdiClipboardCheck }}
                          </v-icon>
                        </v-btn>
                      </span>
                      <span v-else>
                        <v-btn outlined color="white" class="mt-2" type="button" small>
                          <v-icon dark color="success">{{ icons.mdiCheckboxMarkedCircle }} </v-icon></v-btn
                        >
                      </span>
                    </span>
                    <span v-else>
                      <v-btn color="error" class="mt-2" type="button" small disabled>
                        <v-icon dark color="white">
                          {{ icons.mdiClipboardCheck }}
                        </v-icon>
                      </v-btn>
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="9">
                    <center>
                      <div class="v-data-footer">
                        <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                        <span class="v-data-footer__icons-before" bis_skin_checked="1">
                          <button
                            type="button"
                            @click="pagepaid--"
                            :class="[
                              'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              pagepaid == 1 ? 'v-btn--disabled' : '',
                            ]"
                            aria-label="Previous page"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                        <span
                          :class="['v-data-footer', pageNumber == pagepaid ? 'active' : '']"
                          v-for="pageNumber in pagespaid.slice(pagepaid - 1, pagepaid + 5)"
                          :key="pageNumber"
                          @click="pagepaid = pageNumber"
                        >
                          <!-- <a href="#">{{ pageNumber }}</a> -->
                          <button
                            type="button"
                            class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                            aria-label="Next page"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>
                        <span class="v-data-footer__icons-after" bis_skin_checked="1">
                          <button
                            type="button"
                            :class="[
                              'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              pagepaid < pagespaid.length ? '' : 'v-btn--disabled',
                            ]"
                            aria-label="Next page"
                            @click="pagepaid++"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                      </div>
                    </center>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-card-title class="text-no-wrap pt-1 ps-2"> VISA WILL EXPIRED 🥳 </v-card-title>
          <v-text-field class="pt-5" label="Search" @keyup="doSearch()" v-model="search" dense outlined></v-text-field>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">NO</th>
                  <th class="text-center text-uppercase">NAME</th>
                  <th class="text-center text-uppercase">EXPIRED DATE</th>
                  <th class="text-center text-uppercase">CITIZENSHIP</th>
                  <th class="text-center text-uppercase">GENDER</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in displayedVisarem()" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-center">{{ data.nama_depan }} {{ data.nama_belakang }}</td>
                  <td class="text-center" :key="keyTgl_kadaluarsa">
                    {{ data.tgl_kadaluarsa | date }}
                  </td>
                  <td class="text-center" v-if="data.kewarganegaraan == null" v-html="'<strong>-</strong>'"></td>
                  <td class="text-center" v-else>
                    {{ data.nama_negara }}
                  </td>

                  <td class="text-center">
                    <span v-if="data.jenis_kelamin == 'L'"> Laki-laki </span>
                    <span v-else> Perempuan </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="6">
                    <center>
                      <div class="v-data-footer">
                        <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                        <span class="v-data-footer__icons-before" bis_skin_checked="1">
                          <button
                            type="button"
                            @click="page--"
                            :class="[
                              'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              page == 1 ? 'v-btn--disabled' : '',
                            ]"
                            aria-label="Previous page"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                        <span
                          :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                          v-for="pageNumber in pages.slice(page - 1, page + 5)"
                          :key="pageNumber"
                          @click="page = pageNumber"
                        >
                          <!-- <a href="#">{{ pageNumber }}</a> -->
                          <button
                            type="button"
                            class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                            aria-label="Next page"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>
                        <span class="v-data-footer__icons-after" bis_skin_checked="1">
                          <button
                            type="button"
                            :class="[
                              'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              page < pages.length ? '' : 'v-btn--disabled',
                            ]"
                            aria-label="Next page"
                            @click="page++"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                      </div>
                    </center>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-card-title class="text-no-wrap pt-1 ps-2"> VISA EXPIRED 🥳 </v-card-title>
          <v-text-field
            class="pt-5"
            label="Search"
            @keyup="doSearchexp()"
            v-model="searchexp"
            dense
            outlined
          ></v-text-field>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">NO</th>
                  <th class="text-center text-uppercase">NAME</th>
                  <th class="text-center text-uppercase">EXPIRED DATE</th>
                  <th class="text-center text-uppercase">CITIZENSHIP</th>
                  <th class="text-center text-uppercase">GENDER</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dataexp, index) in displayedVisexp()" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-center">{{ dataexp.nama_depan }} {{ dataexp.nama_belakang }}</td>
                  <td class="text-center" :key="keyTgl_kadaluarsa">
                    {{ dataexp.tgl_kadaluarsa | date }}
                  </td>
                  <td class="text-center" v-if="dataexp.kewarganegaraan == null" v-html="'<strong>-</strong>'"></td>
                  <td class="text-center" v-else>
                    {{ dataexp.nama_negara }}
                  </td>

                  <td class="text-center">
                    <span v-if="dataexp.jenis_kelamin == 'L'"> Laki-laki </span>
                    <span v-else> Perempuan </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="6">
                    <center>
                      <div class="v-data-footer">
                        <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                        <span class="v-data-footer__icons-before" bis_skin_checked="1">
                          <button
                            type="button"
                            @click="pageexp--"
                            :class="[
                              'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              pageexp == 1 ? 'v-btn--disabled' : '',
                            ]"
                            aria-label="Previous page"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                        <span
                          :class="['v-data-footer', pageNumber == pageexp ? 'active' : '']"
                          v-for="pageNumber in pagesexp.slice(pageexp - 1, pageexp + 5)"
                          :key="pageNumber"
                          @click="pageexp = pageNumber"
                        >
                          <!-- <a href="#">{{ pageNumber }}</a> -->
                          <button
                            type="button"
                            class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                            aria-label="Next page"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>
                        <span class="v-data-footer__icons-after" bis_skin_checked="1">
                          <button
                            type="button"
                            :class="[
                              'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                              pageexp < pagesexp.length ? '' : 'v-btn--disabled',
                            ]"
                            aria-label="Next page"
                            @click="pageexp++"
                          >
                            <span class="v-btn__content"
                              ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  role="img"
                                  aria-hidden="true"
                                  class="v-icon__svg"
                                >
                                  <path
                                    d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                  ></path></svg></span
                            ></span>
                          </button>
                        </span>
                      </div>
                    </center>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiClipboardArrowUp,
  mdiClipboardCheck,
  mdiCheckboxMarkedCircle,
} from '@mdi/js'

export default {
  data() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Visa') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Members') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiClipboardArrowUp,
        mdiClipboardCheck,
        mdiCheckboxMarkedCircle,
      },

      member: [],
      visa: [],
      pesan: [],
      berkas: [],

      jmlmember: [],
      jmlvisa: [],
      jmlpesan: [],
      jmlberkas: [],
      jmlstatusijintinggal: [],
      jmltransaksiidr: [],
      jmltransaksiusd: [],
      tagihanidr: [],
      tagihandollar: [],

      visa_rem: [],
      visa_exp: [],
      keyTgl_kadaluarsa: 0,

      datatgltransaksi: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageexp: 1,
      perPageexp: 10,
      pagesexp: [],
      searchexp: '',
      searchResultexp: [],

      pagepaid: 1,
      perPagepaid: 10,
      pagespaid: [],
      searchpaid: '',
      searchResultpaid: [],
    }
  },
  // watch: {
  //   '$route.query.params': {
  //     deep: true,
  //     immediate: true,
  //     handler() {
  //       this.getShowOrder()
  //     },
  //   },
  // },
  created() {
    this.$isLoading(true)
    this.getAllData()
    this.getShowOrder()
    setTimeout(() => {
      this.$isLoading(false)
      // this.isToday()
      // this.uppercase();
    }, 500)
  },
  methods: {
    processPost(id, index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'Visa process will be update.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            let uriProcess = process.env.VUE_APP_ROOT_API + `/api/prosesvisa/${id}`
            this.axios
              .post(uriProcess)
              .then(response => {
                // this.costs.splice(this.costs.index, 1)
                console.log('LOG' + response.status)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Update successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  window.location.reload()
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, cann process!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'Process failed to update!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000,
                })
              })
          }
        })
    },

    finishPost(id, index) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'Visa process will be update.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            let uriProcess = process.env.VUE_APP_ROOT_API + `/api/finishvisa/${id}`
            this.axios
              .post(uriProcess)
              .then(response => {
                // this.costs.splice(this.costs.index, 1)
                console.log('LOG' + response.status)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Update successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  window.location.reload()
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, cann process!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'Process failed to update!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000,
                })
              })
          }
        })
    },
    // isToday(date) {
    //   return moment(date).format('D MMMM Y')
    // },
    getAllData() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/dashboard'
      return this.axios
        .get(url)
        .then(response => {
          this.member = response.data.member
          this.visa = response.data.visa
          this.pesan = response.data.pesan
          this.berkas = response.data.berkas

          this.jmlmember = response.data.jmlmembers
          this.jmlvisa = response.data.jmlvisa
          this.jmlpesan = response.data.jmlpesan
          this.jmlberkas = response.data.jmlberkas
          this.jmlstatusijintinggal = response.data.jmlstatusijintinggal
          this.jmltransaksiidr = response.data.jmltransaksiidr
          this.jmltransaksiusd = response.data.jmltransaksiusd
          this.tagihanidr = response.data.tagihanidr
          this.tagihandollar = response.data.tagihandollar

          this.visa_rem = response.data.visa_rem
          this.visa_exp = response.data.visa_exp
        })
        .finally(() => {
          this.visa.forEach(v => {
            this.visa_rem.forEach(e => {
              if (v.id_member === e.id_member) {
                e.tgl_kadaluarsa = v.tgl_kadaluarsa
                this.keyTgl_kadaluarsa++
              }
            })
          })
        })
    },

    doSearch() {
      let go = this.visa_rem.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_depan.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayedVisarem() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.visa_rem)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.visa_rem.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(visa_rem) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return visa_rem.slice(from, to)
    },

    doSearchexp() {
      let go = this.visa_exp.filter(item => {
        return this.searchexp
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_depan.toLowerCase().includes(v))
      })
      this.searchResultexp = go
      this.pagesexp = []
      this.setPagesexp()
    },

    displayedVisexp() {
      let paginateexp

      if (this.searchexp == '') {
        paginateexp = this.paginateexp(this.visa_exp)
      } else {
        paginateexp = this.paginateexp(this.searchResultexp)
      }

      return paginateexp
    },
    setPagesexp() {
      let numberOfPages

      if (this.searchexp == '') {
        numberOfPages = Math.ceil(this.visa_exp.length / this.perPageexp)
      } else {
        numberOfPages = Math.ceil(this.searchResultexp.length / this.perPageexp)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesexp.push(index)
      }
    },
    paginateexp(visa_exp) {
      let pageexp = this.pageexp
      let perPageexp = this.perPageexp
      let from = pageexp * perPageexp - perPageexp
      let to = pageexp * perPageexp
      return visa_exp.slice(from, to)
    },

    // Payed
    displayedPaid() {
      let paginatepaid

      if (this.searchpaid == '') {
        paginatepaid = this.paginatepaid(this.datatgltransaksi)
      } else {
        paginatepaid = this.paginatepaid(this.searchResultpaid)
      }

      return paginatepaid
    },
    setPagespaid() {
      let numberOfPages

      if (this.searchpaid == '') {
        numberOfPages = Math.ceil(this.datatgltransaksi.length / this.perPagepaid)
      } else {
        numberOfPages = Math.ceil(this.searchResultpaid.length / this.perPagepaid)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagespaid.push(index)
      }
    },
    paginatepaid(datatgltransaksi) {
      let pagepaid = this.pagepaid
      let perPagepaid = this.perPagepaid
      let from = pagepaid * perPagepaid - perPagepaid
      let to = pagepaid * perPagepaid
      return datatgltransaksi.slice(from, to)
    },

    getShowOrder() {
      //state token
      localStorage.getItem('token')
      let urlShowOrder = process.env.VUE_APP_ROOT_API + '/api/transaksi/showorder'
      return this.axios.get(urlShowOrder).then(response => {
        this.showorder = response.data.chart[0]
        this.datatgltransaksi = response.data.datatgltransaksi
        this.countdatatgltransaksi = response.data.countdatatgltransaksi
        // let newData = [];
        // this.datatgltransaksi.forEach((value, index) => {
        //   newData.push(value);

        //   this.conversionusdidr = value.sub_total_usd * this.usdidrapi;
        //   console.log("IDR USD : " + this.conversionusdidr);
        // });
      })
    },
  },

  watch: {
    visa_rem() {
      this.pages = []
      this.setPages()
    },

    visa_exp() {
      this.pagesexp = []
      this.setPagesexp()
    },

    datatgltransaksi() {
      this.pagespaid = []
      this.setPagespaid()
    },
  },
}
</script>
